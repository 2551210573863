// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-js": () => import("../src/Templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/Templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-intro-page-js": () => import("../src/Templates/IntroPage.js" /* webpackChunkName: "component---src-templates-intro-page-js" */),
  "component---src-templates-unit-intro-page-js": () => import("../src/Templates/UnitIntroPage.js" /* webpackChunkName: "component---src-templates-unit-intro-page-js" */),
  "component---src-templates-planes-page-js": () => import("../src/Templates/PlanesPage.js" /* webpackChunkName: "component---src-templates-planes-page-js" */),
  "component---src-templates-war-list-page-js": () => import("../src/Templates/WarListPage.js" /* webpackChunkName: "component---src-templates-war-list-page-js" */),
  "component---src-templates-timeline-page-js": () => import("../src/Templates/TimelinePage.js" /* webpackChunkName: "component---src-templates-timeline-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

